.privacy-policy {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.privacy-policy h1 {
  font-size: 24px;
  color: #333;
}

.privacy-policy h2 {
  font-size: 20px;
  color: #555;
  margin-top: 20px;
}

.privacy-policy p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.privacy-policy ul {
  margin: 10px 0;
  padding-left: 20px;
}

.privacy-policy ul li {
  font-size: 16px;
  color: #666;
}

.privacy-policy address {
  font-style: normal;
  margin-top: 10px;
}

.privacy-policy a {
  color: #1a73e8;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}